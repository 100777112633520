import React from 'react';
import {Container, Row, Col} from "react-bootstrap";

import './style.scss'

class Navigation extends React.Component {

    navigationItems = [
        {
            label: 'VAT Calculator',
            url: '#',
        },
        {
            label: 'VAT Rate',
            url: '#',
        },
        {
            label: 'Flat Rate Scheme',
            url: '#',
        },
        {
            label: 'Help to Pay VAT',
            url: '#',
        },
        {
            label: 'Contact HMRC',
            url: '#',
        },
    ];

    render() {
        const items = this.navigationItems;
        return (
            <div className={'navigation'}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className={'navigation-wrap'}>
                                {
                                    items.map((item, key) => {
                                        return <a href={item.url} className={'nav-item'} key={key}>{item.label}</a>
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Navigation;
