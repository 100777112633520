import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Range } from 'react-range';

import './style.scss';

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [20],
      result: '',
      vacResults: {
        net: 0,
        vat: 0,
        gross: 0,
      },
      calculations: false,
      message: '',
    };
  }

  renderButtons = (number, key) => {
    return (
      <div
        className="num-button"
        key={key}
        data-value={number}
        onClick={e => this.handleNumberOnClick(e)}
      >
        {number}
      </div>
    );
  };

  handleNumberOnClick = e => {
    this.setState({ message: '' });
    const result = `${this.state.result}${e.currentTarget.dataset.value}`;
    const test = result.replace(/,/g, '');
    this.setState({
      result: test.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    });
  };

  handleResultInput = e => {
    const result = e.currentTarget.value.replace(/,/g, '');
    const parsed = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.setState({ result: parsed, message: '' });
  };

  handleAddVat = e => {
    e.preventDefault();
    const currentValue = this.state.result.replace(/,/g, '');
    const vat = parseInt(currentValue) * (this.state.values[0] / 100);
    const gross = parseInt(currentValue) + parseFloat(vat);
    const net = parseInt(currentValue);
    this.state.result.length !== 0
      ? this.setState({
          vacResults: {
            vat,
            net,
            gross,
          },
          calculations: true,
          message: '',
        })
      : this.setState({ message: 'Please provide number amount' });
  };

  handleRemoveVat = e => {
    e.preventDefault();
    const currentValue = this.state.result.replace(/,/g, '');
    const temp = parseFloat(`1.${this.state.values[0]}`);
    const gross = parseFloat(currentValue);
    const vat =
      parseFloat(currentValue) - parseFloat(currentValue) / parseFloat(temp);
    const net = parseFloat(currentValue) / temp;
    this.state.result.length !== 0
      ? this.setState({
          vacResults: {
            vat,
            net,
            gross,
          },
          calculations: true,
          message: '',
        })
      : this.setState({ message: 'Please provide number amount' });
  };

  render() {
    const { values, result, vacResults, calculations, message } = this.state;

    return (
      <div className="calculator">
        <Container>
          <Row>
            <Col lg={4} md={12} sm={12}>
              <div className="vac-calc">
                {calculations ? (
                  <div className="vac-results">
                    <div className="overlay-info-item">
                      <div className="overlay-info-item-title">
                        Net Amount (excluding VAT)
                      </div>
                      <div className="overlay-info-item-content">
                        <input type="text" disabled value={vacResults.net} />
                      </div>
                    </div>
                    <div className="overlay-info-item">
                      <div className="overlay-info-item-title">
                        VAT (at {values[0]}% )
                      </div>
                      <div className="overlay-info-item-content">
                        <input type="text" disabled value={vacResults.vat} />
                      </div>
                    </div>
                    <div className="overlay-info-item">
                      <div className="overlay-info-item-title">
                        Gross Amount (including VAT):
                      </div>
                      <div className="overlay-info-item-content">
                        <input type="text" disabled value={vacResults.gross} />
                      </div>
                    </div>
                    <div className="overlay-info-item">
                      <div className="overlay-info-item-title">
                        Calculation based on:
                      </div>
                      <div className="overlay-info-item-content">
                        <input
                          type="text"
                          disabled
                          value={parseFloat(result.replace(/,/g, ''))}
                        />
                      </div>
                    </div>
                    <span
                      className="reset-button"
                      onClick={() =>
                        this.setState({
                          calculations: false,
                          result: '',
                          values: [20],
                        })
                      }
                    >
                      Start Again
                    </span>
                  </div>
                ) : null}
                {message || null}
                <div className="input-holder">
                  <span className="input-sign">£</span>
                  <input
                    type="text"
                    className="result-input"
                    value={result}
                    pattern="[0-9]*"
                    onChange={e => this.handleResultInput(e)}
                  />
                </div>
                <div className="buttons-wrapper">
                  {Array.from(
                    { length: 9 },
                    (v, k) => k + 1,
                  ).map((item, index) => this.renderButtons(item, index))}
                  {this.renderButtons(0)}
                </div>
                <div className="action-buttons-wrapper">
                  <button
                    className="action-button btn-1"
                    onClick={e => this.handleAddVat(e)}
                  >
                    ADD VAT
                  </button>
                  <button
                    className="action-button btn-2"
                    onClick={e => this.handleRemoveVat(e)}
                  >
                    REMOVE VAT
                  </button>
                </div>
                <div className="vat-title">VAT Rate</div>
                <div className="slider-wrapper">
                  <Range
                    step={0.5}
                    min={10}
                    max={25}
                    values={this.state.values}
                    onChange={values => this.setState({ values })}
                    renderTrack={({ props, children }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '6px',
                          width: '100%',
                          backgroundColor: '#05857B',
                        }}
                      >
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: 35,
                          width: 25,
                          backgroundColor: '#05857B',
                        }}
                      />
                    )}
                  />
                  <div className="slide-result">{values}%</div>
                </div>
              </div>
            </Col>
            <Col lg={8} md={12} sm={12}>
              <div className="vac-info-wrapper">
                <h1 className="vac-content-title">Welcome to VAT Caculators</h1>
                <div className="vac-content">
                  <p>
                    Welcome to our site. We have created this site due to the
                    recent changes to VAT rates. We could not find a similar
                    site that allowed users to change the amount of VAT so
                    created this so that business users and individuals have
                    access to a calculator where the VAT rate could be changed
                    and VAT could be added to a net amount or subtracted from a
                    gross amount in an easy manner.
                  </p>
                  <p>
                    The VAT rate for the UK currently stands at 20 per cent,
                    this was changed from 17.5 per cent on the 4th of January
                    2011. The standard rate of VAT was temporarily reduced to 15
                    per cent on 1 December 2008. This was put back to 17.5% on
                    the 20th January 2010.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Calculator;
