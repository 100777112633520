import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './style.scss';

const footerNavItems = [
  {
    label: 'Home',
    url: '#',
  },
  {
    label: 'Contact',
    url: '#',
  },
  {
    label: 'Privacy',
    url: '#',
  },
  {
    label: 'TOS',
    url: '#',
  },
  {
    label: 'FAQ',
    url: '#',
  },
];

const renderFooterNavLinks = _ =>
  footerNavItems.map((item, key) => <a className={'footer-nav-link'} key={key} href={item.url}>{item.label}</a>);

export default () => {
  return <footer className={'footer'}>
    <Container>
      <Row>
        <Col>
          <div className={'footer-navigation'}>
            {
              renderFooterNavLinks()
            }
          </div>
          <div className={'copyright-info'}>
            <p className={'centered'}>
              © 2016 all rights reserved. Trademarks used for identification only
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>;
};
