import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import './style.scss';

export default () => {
  return <Container>
    <Row>
      <Col xs={12}>
        <p className={'centered color-blue'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod te
        </p>
      </Col>
    </Row>
    <Row className={'back-text'}>
      <Col xs={12}>
        <p>
          Welcome to our site. We have created this site due to the recent changes to VAT rates. We could not
          find a similar site that allowed users to change the amount of VAT so created this so that business
          users and individuals have access to a calculator where the VAT rate could be changed and VAT could
          be added to a net amount or subtracted from a gross amount in an easy manner.
        </p>
        <p>
          The VAT rate for the UK currently stands at 20 per cent, this was changed from 17.5 per cent on the
          4th of January 2011. The standard rate of VAT was temporarily reduced to 15 per cent on 1 December
          2008. This was put back to 17.5% on the 20th January 2010.
        </p>
      </Col>
    </Row>
  </Container>;

}
