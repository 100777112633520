import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';

import Header from './Components/Header/Header';
import Navigation from './Components/Navigation/Navigation';
import Calculator from './Components/Calculator/Calculator';
import Info from './Components/Info/Info';
import Footer from './Components/Footer/Footer';

class App extends React.PureComponent {
  render() {
    return (
      <div className="app">
        <Header />
        <Navigation />
        <Calculator />
        <Info />
        <Footer />
      </div>
    );
  }
}

export default App;
