import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Logo from '../../assets/logo.png';
import './style.scss';

class Header extends React.Component {
  render() {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <header>
              <img src={Logo} alt="" style={{ width: 250 }}/>
            </header>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Header;
